import React, { useState, useEffect, useContext } from 'react';
import listaPilotosContext from '../../context/listaPilotos/listaPilotosContext';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import AlertaContext from '../../context/alertas/alertaContext';
import MainNavigation from '../partials/MainNavigation';
import { Link } from 'react-router-dom';

import '../../design/styles/draw.scss';

const Sorteo = (props) => {
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    let index = localStorage.getItem('index');
    let currentRace = JSON.parse(localStorage.getItem('currentRace'));

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {actualizarParticipante} = listaContext;
    const {participantesInscritosWarm, obtenerParticipantesWarm} = listaContext;
    const {participantesInscritosBox, obtenerParticipantesBox} = listaContext;
    const {participantesInscritosWomen, obtenerParticipantesWomen} = listaContext;

    // Extraer concursos de state inicial
    const pilotosContext = useContext(listaPilotosContext);
    const {pilotos, obtenerPilotos} = pilotosContext;

    const [selectedOptionPilotOne, setSelectedOptionPilotOne] = useState(null);

    const [isOpenPilotOne, setIsOpenPilotOne] = useState(false);

    const togglingPilotOne = (event) => {
        setIsOpenPilotOne(!isOpenPilotOne)
    };

    const onOptionClickedPilotOne = value => () => {
        setSelectedOptionPilotOne(value);
        setIsOpenPilotOne(false);
    };

    const [selectedOptionPilotTwo, setSelectedOptionPilotTwo] = useState(null);

    const [isOpenPilotTwo, setIsOpenPilotTwo] = useState(false);

    const togglingPilotTwo = (event) => {
        setIsOpenPilotTwo(!isOpenPilotTwo)
    };

    const onOptionClickedPilotTwo = value => () => {
        setSelectedOptionPilotTwo(value);
        setIsOpenPilotTwo(false);
    };

    const [selectedOptionPilotThree, setSelectedOptionPilotThree] = useState(null);

    const [isOpenPilotThree, setIsOpenPilotThree] = useState(false);

    const togglingPilotThree = (event) => {
        setIsOpenPilotThree(!isOpenPilotThree)
    };

    const onOptionClickedPilotThree = value => () => {
        setSelectedOptionPilotThree(value);
        setIsOpenPilotThree(false);
    };

    const [selectedOptionPilotFour, setSelectedOptionPilotFour] = useState(null);

    const [isOpenPilotFour, setIsOpenPilotFour] = useState(false);

    const togglingPilotFour = (event) => {
        setIsOpenPilotFour(!isOpenPilotFour)
    };

    const onOptionClickedPilotFour = value => () => {
        setSelectedOptionPilotFour(value);
        setIsOpenPilotFour(false);
    };

    const [selectedOptionPilotFive, setSelectedOptionPilotFive] = useState(null);

    const [isOpenPilotFive, setIsOpenPilotFive] = useState(false);

    const togglingPilotFive = (event) => {
        setIsOpenPilotFive(!isOpenPilotFive)
    };

    const onOptionClickedPilotFive = value => () => {
        setSelectedOptionPilotFive(value);
        setIsOpenPilotFive(false);
    };

    const [selectedOptionPilotSix, setSelectedOptionPilotSix] = useState(null);

    const [isOpenPilotSix, setIsOpenPilotSix] = useState(false);

    const togglingPilotSix = (event) => {
        setIsOpenPilotSix(!isOpenPilotSix)
    };

    const onOptionClickedPilotSix = value => () => {
        setSelectedOptionPilotSix(value);
        setIsOpenPilotSix(false);
    };

    const [selectedOptionPilotSeven, setSelectedOptionPilotSeven] = useState(null);

    const [isOpenPilotSeven, setIsOpenPilotSeven] = useState(false);

    const togglingPilotSeven = (event) => {
        setIsOpenPilotSeven(!isOpenPilotSeven)
    };

    const onOptionClickedPilotSeven = value => () => {
        setSelectedOptionPilotSeven(value);
        setIsOpenPilotSeven(false);
    };

    const [selectedOptionPilotEight, setSelectedOptionPilotEight] = useState(null);

    const [isOpenPilotEight, setIsOpenPilotEight] = useState(false);

    const togglingPilotEight = (event) => {
        setIsOpenPilotEight(!isOpenPilotEight)
    };

    const onOptionClickedPilotEight = value => () => {
        setSelectedOptionPilotEight(value);
        setIsOpenPilotEight(false);
    };

    const [selectedOptionPilotNine, setSelectedOptionPilotNine] = useState(null);

    const [isOpenPilotNine, setIsOpenPilotNine] = useState(false);

    const togglingPilotNine = (event) => {
        setIsOpenPilotNine(!isOpenPilotNine)
    };

    const onOptionClickedPilotNine = value => () => {
        setSelectedOptionPilotNine(value);
        setIsOpenPilotNine(false);
    };

    const [selectedOptionPilotTen, setSelectedOptionPilotTen] = useState(null);

    const [isOpenPilotTen, setIsOpenPilotTen] = useState(false);

    const togglingPilotTen = (event) => {
        setIsOpenPilotTen(!isOpenPilotTen)
    };

    const onOptionClickedPilotTen = value => () => {
        setSelectedOptionPilotTen(value);
        setIsOpenPilotTen(false);
    };

    const [selectedOptionPilotEleven, setSelectedOptionPilotEleven] = useState(null);

    const [isOpenPilotEleven, setIsOpenPilotEleven] = useState(false);

    const togglingPilotEleven = (event) => {
        setIsOpenPilotEleven(!isOpenPilotEleven)
    };

    const onOptionClickedPilotEleven = value => () => {
        setSelectedOptionPilotEleven(value);
        setIsOpenPilotEleven(false);
    };

    const [selectedOptionPilotTwelve, setSelectedOptionPilotTwelve] = useState(null);

    const [isOpenPilotTwelve, setIsOpenPilotTwelve] = useState(false);

    const togglingPilotTwelve = (event) => {
        setIsOpenPilotTwelve(!isOpenPilotTwelve)
    };

    const onOptionClickedPilotTwelve = value => () => {
        setSelectedOptionPilotTwelve(value);
        setIsOpenPilotTwelve(false);
    };

    const [selectedOptionPilotThirteen, setSelectedOptionPilotThirteen] = useState(null);

    const [isOpenPilotThirteen, setIsOpenPilotThirteen] = useState(false);

    const togglingPilotThirteen = (event) => {
        setIsOpenPilotThirteen(!isOpenPilotThirteen)
    };

    const onOptionClickedPilotThirteen = value => () => {
        setSelectedOptionPilotThirteen(value);
        setIsOpenPilotThirteen(false);
    };

    const [selectedOptionPilotForteen, setSelectedOptionPilotForteen] = useState(null);

    const [isOpenPilotForteen, setIsOpenPilotForteen] = useState(false);

    const togglingPilotForteen = (event) => {
        setIsOpenPilotForteen(!isOpenPilotForteen)
    };

    const onOptionClickedPilotForteen = value => () => {
        setSelectedOptionPilotForteen(value);
        setIsOpenPilotForteen(false);
    };

    const [selectedOptionPilotFifteen, setSelectedOptionPilotFifteen] = useState(null);

    const [isOpenPilotFifteen, setIsOpenPilotFifteen] = useState(false);

    const togglingPilotFifteen = (event) => {
        setIsOpenPilotFifteen(!isOpenPilotFifteen)
    };

    const onOptionClickedPilotFifteen = value => () => {
        setSelectedOptionPilotFifteen(value);
        setIsOpenPilotFifteen(false);
    };

    const [selectedOptionPilotSixteen, setSelectedOptionPilotSixteen] = useState(null);

    const [isOpenPilotSixteen, setIsOpenPilotSixteen] = useState(false);

    const togglingPilotSixteen = (event) => {
        setIsOpenPilotSixteen(!isOpenPilotSixteen)
    };

    const onOptionClickedPilotSixteen = value => () => {
        setSelectedOptionPilotSixteen(value);
        setIsOpenPilotSixteen(false);
    };

    const [selectedOptionPilotSeventeen, setSelectedOptionPilotSeventeen] = useState(null);

    const [isOpenPilotSeventeen, setIsOpenPilotSeventeen] = useState(false);

    const togglingPilotSeventeen = (event) => {
        setIsOpenPilotSeventeen(!isOpenPilotSeventeen)
    };

    const onOptionClickedPilotSeventeen = value => () => {
        setSelectedOptionPilotSeventeen(value);
        setIsOpenPilotSeventeen(false);
    };

    const [selectedOptionPilotEighteen, setSelectedOptionPilotEighteen] = useState(null);

    const [isOpenPilotEighteen, setIsOpenPilotEighteen] = useState(false);

    const togglingPilotEighteen = (event) => {
        setIsOpenPilotEighteen(!isOpenPilotEighteen)
    };

    const onOptionClickedPilotEighteen = value => () => {
        setSelectedOptionPilotEighteen(value);
        setIsOpenPilotEighteen(false);
    };

    const [selectedOptionPilotNineteen, setSelectedOptionPilotNineteen] = useState(null);

    const [isOpenPilotNineteen, setIsOpenPilotNineteen] = useState(false);

    const togglingPilotNineteen = (event) => {
        setIsOpenPilotNineteen(!isOpenPilotNineteen)
    };

    const onOptionClickedPilotNineteen = value => () => {
        setSelectedOptionPilotNineteen(value);
        setIsOpenPilotNineteen(false);
    };

    const [selectedOptionPilotTwenty, setSelectedOptionPilotTwenty] = useState(null);

    const [isOpenPilotTwenty, setIsOpenPilotTwenty] = useState(false);

    const togglingPilotTwenty = (event) => {
        setIsOpenPilotTwenty(!isOpenPilotTwenty)
    };

    const onOptionClickedPilotTwenty = value => () => {
        setSelectedOptionPilotTwenty(value);
        setIsOpenPilotTwenty(false);
    };

    const [selectedOptionConstructorOne, setSelectedOptionConstructorOne] = useState({});

    const [isOpenConstructorOne, setIsOpenConstructorOne] = useState(false);

    const togglingConstructorOne = () => setIsOpenConstructorOne(!isOpenConstructorOne);

    const onOptionClickedConstructorOne = value => () => {
        setSelectedOptionConstructorOne(value);
        setIsOpenConstructorOne(false);
    };

    const [selectedOptionConstructorTwo, setSelectedOptionConstructorTwo] = useState({});

    const [isOpenConstructorTwo, setIsOpenConstructorTwo] = useState(false);

    const togglingConstructorTwo = () => setIsOpenConstructorTwo(!isOpenConstructorTwo);

    const onOptionClickedConstructorTwo = value => () => {
        setSelectedOptionConstructorTwo(value);
        setIsOpenConstructorTwo(false);
    };

    const [selectedOptionConstructorThree, setSelectedOptionConstructorThree] = useState({});

    const [isOpenConstructorThree, setIsOpenConstructorThree] = useState(false);

    const togglingConstructorThree = () => setIsOpenConstructorThree(!isOpenConstructorThree);

    const onOptionClickedConstructorThree = value => () => {
        setSelectedOptionConstructorThree(value);
        setIsOpenConstructorThree(false);
    };

    const [selectedOptionConstructorFour, setSelectedOptionConstructorFour] = useState({});

    const [isOpenConstructorFour, setIsOpenConstructorFour] = useState(false);

    const togglingConstructorFour = () => setIsOpenConstructorFour(!isOpenConstructorFour);

    const onOptionClickedConstructorFour = value => () => {
        setSelectedOptionConstructorFour(value);
        setIsOpenConstructorFour(false);
    };

    const [selectedOptionConstructorFive, setSelectedOptionConstructorFive] = useState({});

    const [isOpenConstructorFive, setIsOpenConstructorFive] = useState(false);

    const togglingConstructorFive = () => setIsOpenConstructorFive(!isOpenConstructorFive);

    const onOptionClickedConstructorFive = value => () => {
        setSelectedOptionConstructorFive(value);
        setIsOpenConstructorFive(false);
    };

    const [selectedOptionConstructorSix, setSelectedOptionConstructorSix] = useState({});

    const [isOpenConstructorSix, setIsOpenConstructorSix] = useState(false);

    const togglingConstructorSix = () => setIsOpenConstructorSix(!isOpenConstructorSix);

    const onOptionClickedConstructorSix = value => () => {
        setSelectedOptionConstructorSix(value);
        setIsOpenConstructorSix(false);
    };

    const [selectedOptionConstructorSeven, setSelectedOptionConstructorSeven] = useState({});

    const [isOpenConstructorSeven, setIsOpenConstructorSeven] = useState(false);

    const togglingConstructorSeven = () => setIsOpenConstructorSeven(!isOpenConstructorSeven);

    const onOptionClickedConstructorSeven = value => () => {
        setSelectedOptionConstructorSeven(value);
        setIsOpenConstructorSeven(false);
    };

    const [selectedOptionConstructorEight, setSelectedOptionConstructorEight] = useState({});

    const [isOpenConstructorEight, setIsOpenConstructorEight] = useState(false);

    const togglingConstructorEight = () => setIsOpenConstructorEight(!isOpenConstructorEight);

    const onOptionClickedConstructorEight = value => () => {
        setSelectedOptionConstructorEight(value);
        setIsOpenConstructorEight(false);
    };

    const [selectedOptionConstructorNine, setSelectedOptionConstructorNine] = useState({});

    const [isOpenConstructorNine, setIsOpenConstructorNine] = useState(false);

    const togglingConstructorNine = () => setIsOpenConstructorNine(!isOpenConstructorNine);

    const onOptionClickedConstructorNine = value => () => {
        setSelectedOptionConstructorNine(value);
        setIsOpenConstructorNine(false);
    };

    const [selectedOptionConstructorTen, setSelectedOptionConstructorTen] = useState({});

    const [isOpenConstructorTen, setIsOpenConstructorTen] = useState(false);

    const togglingConstructorTen = () => setIsOpenConstructorTen(!isOpenConstructorTen);

    const onOptionClickedConstructorTen = value => () => {
        setSelectedOptionConstructorTen(value);
        setIsOpenConstructorTen(false);
    };

    const [selectedOptionConstructorEleven, setSelectedOptionConstructorEleven] = useState({});

    const [isOpenConstructorEleven, setIsOpenConstructorEleven] = useState(false);

    const togglingConstructorEleven = () => setIsOpenConstructorEleven(!isOpenConstructorEleven);

    const onOptionClickedConstructorEleven = value => () => {
        setSelectedOptionConstructorEleven(value);
        setIsOpenConstructorEleven(false);
    };

    const [selectedOptionConstructorTwelve, setSelectedOptionConstructorTwelve] = useState({});

    const [isOpenConstructorTwelve, setIsOpenConstructorTwelve] = useState(false);

    const togglingConstructorTwelve = () => setIsOpenConstructorTwelve(!isOpenConstructorTwelve);

    const onOptionClickedConstructorTwelve = value => () => {
        setSelectedOptionConstructorTwelve(value);
        setIsOpenConstructorTwelve(false);
    };

    const [selectedOptionConstructorThirteen, setSelectedOptionConstructorThirteen] = useState({});

    const [isOpenConstructorThirteen, setIsOpenConstructorThirteen] = useState(false);

    const togglingConstructorThirteen = () => setIsOpenConstructorThirteen(!isOpenConstructorThirteen);

    const onOptionClickedConstructorThirteen = value => () => {
        setSelectedOptionConstructorThirteen(value);
        setIsOpenConstructorThirteen(false);
    };

    const [selectedOptionConstructorForteen, setSelectedOptionConstructorForteen] = useState({});

    const [isOpenConstructorForteen, setIsOpenConstructorForteen] = useState(false);

    const togglingConstructorForteen = () => setIsOpenConstructorForteen(!isOpenConstructorForteen);

    const onOptionClickedConstructorForteen = value => () => {
        setSelectedOptionConstructorForteen(value);
        setIsOpenConstructorForteen(false);
    };

    const [selectedOptionConstructorFifteen, setSelectedOptionConstructorFifteen] = useState({});

    const [isOpenConstructorFifteen, setIsOpenConstructorFifteen] = useState(false);

    const togglingConstructorFifteen = () => setIsOpenConstructorFifteen(!isOpenConstructorFifteen);

    const onOptionClickedConstructorFifteen = value => () => {
        setSelectedOptionConstructorFifteen(value);
        setIsOpenConstructorFifteen(false);
    };

    const [selectedOptionConstructorSixteen, setSelectedOptionConstructorSixteen] = useState({});

    const [isOpenConstructorSixteen, setIsOpenConstructorSixteen] = useState(false);

    const togglingConstructorSixteen = () => setIsOpenConstructorSixteen(!isOpenConstructorSixteen);

    const onOptionClickedConstructorSixteen = value => () => {
        setSelectedOptionConstructorSixteen(value);
        setIsOpenConstructorSixteen(false);
    };

    const [selectedOptionConstructorSeventeen, setSelectedOptionConstructorSeventeen] = useState({});

    const [isOpenConstructorSeventeen, setIsOpenConstructorSeventeen] = useState(false);

    const togglingConstructorSeventeen = () => setIsOpenConstructorSeventeen(!isOpenConstructorSeventeen);

    const onOptionClickedConstructorSeventeen = value => () => {
        setSelectedOptionConstructorSeventeen(value);
        setIsOpenConstructorSeventeen(false);
    };

    const [selectedOptionConstructorEighteen, setSelectedOptionConstructorEighteen] = useState({});

    const [isOpenConstructorEighteen, setIsOpenConstructorEighteen] = useState(false);

    const togglingConstructorEighteen = () => setIsOpenConstructorEighteen(!isOpenConstructorEighteen);

    const onOptionClickedConstructorEighteen = value => () => {
        setSelectedOptionConstructorEighteen(value);
        setIsOpenConstructorEighteen(false);
    };

    const [selectedOptionConstructorNineteen, setSelectedOptionConstructorNineteen] = useState({});

    const [isOpenConstructorNineteen, setIsOpenConstructorNineteen] = useState(false);

    const togglingConstructorNineteen = () => setIsOpenConstructorNineteen(!isOpenConstructorNineteen);

    const onOptionClickedConstructorNineteen = value => () => {
        setSelectedOptionConstructorNineteen(value);
        setIsOpenConstructorNineteen(false);
    };

    const [selectedOptionConstructorTwenty, setSelectedOptionConstructorTwenty] = useState({});

    const [isOpenConstructorTwenty, setIsOpenConstructorTwenty] = useState(false);

    const togglingConstructorTwenty = () => setIsOpenConstructorTwenty(!isOpenConstructorTwenty);

    const onOptionClickedConstructorTwenty = value => () => {
        setSelectedOptionConstructorTwenty(value);
        setIsOpenConstructorTwenty(false);
    };

    useEffect(() => {
        obtenerPilotos();
        obtenerParticipantesWarm('65c2abef784e25b32d5224ae');
        obtenerParticipantesBox('65c2abdb784e25b32d5224ad');
        obtenerParticipantesWomen('65c2abca784e25b32d5224ac');
    }, [])

    const constructores = [
        {
            id: 0,
            nombre: 'Red Bull Racing'
        },
        {
            id: 1,
            nombre: 'Mercedes'
        },
        {
            id: 2,
            nombre: 'Ferrari'
        },
        {
            id: 3,
            nombre: 'McLaren'
        },
        {
            id: 4,
            nombre: 'Alpine'
        },
        {
            id: 5,
            nombre: 'RB'
        },
        {
            id: 6,
            nombre: 'Aston Martin'
        },
        {
            id: 7,
            nombre: 'Williams'
        },
        {
            id: 8,
            nombre: 'Kick Sauber'
        },
        {
            id: 9,
            nombre: 'Haas F1 Team'
        }
    ]
    
    const saveInfo = () => {

        if (selectedOptionPilotOne === null ||
            selectedOptionPilotTwo === null ||
            selectedOptionPilotThree === null ||
            selectedOptionPilotFour === null ||
            selectedOptionPilotFive === null ||
            selectedOptionPilotSix === null ||
            selectedOptionPilotSeven === null ||
            selectedOptionPilotEight === null ||
            selectedOptionPilotNine === null ||
            selectedOptionPilotTen === null ||
            selectedOptionPilotEleven === null ||
            selectedOptionPilotTwelve === null ||
            selectedOptionPilotThirteen === null ||
            selectedOptionPilotForteen === null ||
            selectedOptionPilotFifteen === null ||
            selectedOptionPilotSixteen === null ||
            selectedOptionPilotSeventeen === null ||
            selectedOptionPilotEighteen === null ||
            selectedOptionPilotNineteen === null ||
            selectedOptionPilotTwenty === null ||
            selectedOptionConstructorOne.constructorNombre === undefined ||
            selectedOptionConstructorTwo.constructorNombre === undefined ||
            selectedOptionConstructorThree.constructorNombre === undefined ||
            selectedOptionConstructorFour.constructorNombre === undefined ||
            selectedOptionConstructorFive.constructorNombre === undefined ||
            selectedOptionConstructorSix.constructorNombre === undefined ||
            selectedOptionConstructorSeven.constructorNombre === undefined ||
            selectedOptionConstructorEight.constructorNombre === undefined ||
            selectedOptionConstructorNine.constructorNombre === undefined ||
            selectedOptionConstructorTen.constructorNombre === undefined ||
            selectedOptionConstructorEleven.constructorNombre === undefined ||
            selectedOptionConstructorTwelve.constructorNombre === undefined ||
            selectedOptionConstructorThirteen.constructorNombre === undefined ||
            selectedOptionConstructorForteen.constructorNombre === undefined ||
            selectedOptionConstructorFifteen.constructorNombre === undefined ||
            selectedOptionConstructorSixteen.constructorNombre === undefined ||
            selectedOptionConstructorSeventeen.constructorNombre === undefined ||
            selectedOptionConstructorEighteen.constructorNombre === undefined ||
            selectedOptionConstructorNineteen.constructorNombre === undefined ||
            selectedOptionConstructorTwenty.constructorNombre === undefined) {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
            return;
        }

        const arrayValidation = [
            selectedOptionPilotOne,
            selectedOptionPilotTwo,
            selectedOptionPilotThree,
            selectedOptionPilotFour,
            selectedOptionPilotFive,
            selectedOptionPilotSix,
            selectedOptionPilotSeven,
            selectedOptionPilotEight,
            selectedOptionPilotNine,
            selectedOptionPilotTen,
            selectedOptionPilotEleven,
            selectedOptionPilotTwelve,
            selectedOptionPilotThirteen,
            selectedOptionPilotForteen,
            selectedOptionPilotFifteen,
            selectedOptionPilotSixteen,
            selectedOptionPilotSeventeen,
            selectedOptionPilotEighteen,
            selectedOptionPilotNineteen,
            selectedOptionPilotTwenty,
        ]

        let repetido = false;
        for (let i = 0; i < arrayValidation.length; i++) {
            for (let j = 0; j < arrayValidation.length; j++) {
                if (arrayValidation[i] == arrayValidation[j] && i != j) { //revisamos que i sea diferente de j, para que no compare el mismo elemento exacto.
                    repetido= true;
                }
            }
        }

        if(repetido === true) {
            mostrarAlerta('Hay un piloto repetido.', 'alerta-error')
            return
        }

        const arrayConstructorValidation = [
            selectedOptionConstructorOne.constructorId,
            selectedOptionConstructorTwo.constructorId,
            selectedOptionConstructorThree.constructorId,
            selectedOptionConstructorFour.constructorId,
            selectedOptionConstructorFive.constructorId,
            selectedOptionConstructorSix.constructorId,
            selectedOptionConstructorSeven.constructorId,
            selectedOptionConstructorEight.constructorId,
            selectedOptionConstructorNine.constructorId,
            selectedOptionConstructorTen.constructorId,
            selectedOptionConstructorEleven.constructorId,
            selectedOptionConstructorTwelve.constructorId,
            selectedOptionConstructorThirteen.constructorId,
            selectedOptionConstructorForteen.constructorId,
            selectedOptionConstructorFifteen.constructorId,
            selectedOptionConstructorSixteen.constructorId,
            selectedOptionConstructorSeventeen.constructorId,
            selectedOptionConstructorEighteen.constructorId,
            selectedOptionConstructorNineteen.constructorId,
            selectedOptionConstructorTwenty.constructorId
        ].sort()

        let repetidoConstructor = false,
            unicosElementos = [],
            vecesRepetidas = [],
            contador = 1;

        for (let i = 0; i < arrayConstructorValidation.length; i++) {
            if(arrayConstructorValidation[i] === arrayConstructorValidation[i+1]) {
                contador++
                repetidoConstructor = true
            } else {
                unicosElementos.push(arrayConstructorValidation[i])
                vecesRepetidas.push(contador)
                contador = 1
            }
        }

        for (let j = 0; j < unicosElementos.length; j++) {
            if(vecesRepetidas[j] === 3) {
                mostrarAlerta('Hay un constructor repetido.', 'alerta-error')
                return
            }
        }

        mostrarAlerta('Datos correctos.', 'alerta-ok')

        if (index === '18') {
            if (participantesInscritosWarm[0]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[0]._id,
                    sorteo_piloto_18: selectedOptionPilotOne,
                    sorteo_constructor_18: selectedOptionConstructorOne.constructorNombre
                });
            }
            if (participantesInscritosWarm[1]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[1]._id,
                    sorteo_piloto_18: selectedOptionPilotTwo,
                    sorteo_constructor_18: selectedOptionConstructorTwo.constructorNombre
                });
            }
            if (participantesInscritosWarm[2]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[2]._id,
                    sorteo_piloto_18: selectedOptionPilotThree,
                    sorteo_constructor_18: selectedOptionConstructorThree.constructorNombre
                });
            }
            if (participantesInscritosWarm[3]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[3]._id,
                    sorteo_piloto_18: selectedOptionPilotFour,
                    sorteo_constructor_18: selectedOptionConstructorFour.constructorNombre
                });
            }
            if (participantesInscritosWarm[4]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[4]._id,
                    sorteo_piloto_18: selectedOptionPilotFive,
                    sorteo_constructor_18: selectedOptionConstructorFive.constructorNombre
                });
            }
            if (participantesInscritosWarm[5]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[5]._id,
                    sorteo_piloto_18: selectedOptionPilotSix,
                    sorteo_constructor_18: selectedOptionConstructorSix.constructorNombre
                });
            }
            if (participantesInscritosWarm[6]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[6]._id,
                    sorteo_piloto_18: selectedOptionPilotSeven,
                    sorteo_constructor_18: selectedOptionConstructorSeven.constructorNombre
                });
            }
            if (participantesInscritosWarm[7]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[7]._id,
                    sorteo_piloto_18: selectedOptionPilotEight,
                    sorteo_constructor_18: selectedOptionConstructorEight.constructorNombre
                });
            }
            if (participantesInscritosWarm[8]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[8]._id,
                    sorteo_piloto_18: selectedOptionPilotNine,
                    sorteo_constructor_18: selectedOptionConstructorNine.constructorNombre
                });
            }
            if (participantesInscritosWarm[9]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[9]._id,
                    sorteo_piloto_18: selectedOptionPilotTen,
                    sorteo_constructor_18: selectedOptionConstructorTen.constructorNombre
                });
            }
            if (participantesInscritosWarm[10]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[10]._id,
                    sorteo_piloto_18: selectedOptionPilotEleven,
                    sorteo_constructor_18: selectedOptionConstructorEleven.constructorNombre
                });
            }
            if (participantesInscritosWarm[11]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[11]._id,
                    sorteo_piloto_18: selectedOptionPilotTwelve,
                    sorteo_constructor_18: selectedOptionConstructorTwelve.constructorNombre
                });
            }
            if (participantesInscritosWarm[12]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[12]._id,
                    sorteo_piloto_18: selectedOptionPilotThirteen,
                    sorteo_constructor_18: selectedOptionConstructorThirteen.constructorNombre
                });
            }
            if (participantesInscritosWarm[13]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[13]._id,
                    sorteo_piloto_18: selectedOptionPilotForteen,
                    sorteo_constructor_18: selectedOptionConstructorForteen.constructorNombre
                });
            }
            if (participantesInscritosWarm[14]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[14]._id,
                    sorteo_piloto_18: selectedOptionPilotFifteen,
                    sorteo_constructor_18: selectedOptionConstructorFifteen.constructorNombre
                });
            }
            if (participantesInscritosWarm[15]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[15]._id,
                    sorteo_piloto_18: selectedOptionPilotSixteen,
                    sorteo_constructor_18: selectedOptionConstructorSixteen.constructorNombre
                });
            }
            if (participantesInscritosWarm[16]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[16]._id,
                    sorteo_piloto_18: selectedOptionPilotSeventeen,
                    sorteo_constructor_18: selectedOptionConstructorSeventeen.constructorNombre
                });
            }
            if (participantesInscritosWarm[17]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[17]._id,
                    sorteo_piloto_18: selectedOptionPilotEighteen,
                    sorteo_constructor_18: selectedOptionConstructorEighteen.constructorNombre
                });
            }
            if (participantesInscritosWarm[18]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[18]._id,
                    sorteo_piloto_18: selectedOptionPilotNineteen,
                    sorteo_constructor_18: selectedOptionConstructorNineteen.constructorNombre
                });
            }
            if (participantesInscritosWarm[19]) {
                actualizarParticipante({
                    _id: participantesInscritosWarm[19]._id,
                    sorteo_piloto_18: selectedOptionPilotTwenty,
                    sorteo_constructor_18: selectedOptionConstructorTwenty.constructorNombre
                });
            }
            if (participantesInscritosBox[0]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[0]._id,
                    sorteo_piloto_18: selectedOptionPilotOne,
                    sorteo_constructor_18: selectedOptionConstructorOne.constructorNombre
                });
            }
            if (participantesInscritosBox[1]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[1]._id,
                    sorteo_piloto_18: selectedOptionPilotTwo,
                    sorteo_constructor_18: selectedOptionConstructorTwo.constructorNombre
                });
            }
            if (participantesInscritosBox[2]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[2]._id,
                    sorteo_piloto_18: selectedOptionPilotThree,
                    sorteo_constructor_18: selectedOptionConstructorThree.constructorNombre
                });
            }
            if (participantesInscritosBox[3]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[3]._id,
                    sorteo_piloto_18: selectedOptionPilotFour,
                    sorteo_constructor_18: selectedOptionConstructorFour.constructorNombre
                });
            }
            if (participantesInscritosBox[4]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[4]._id,
                    sorteo_piloto_18: selectedOptionPilotFive,
                    sorteo_constructor_18: selectedOptionConstructorFive.constructorNombre
                });
            }
            if (participantesInscritosBox[5]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[5]._id,
                    sorteo_piloto_18: selectedOptionPilotSix,
                    sorteo_constructor_18: selectedOptionConstructorSix.constructorNombre
                });
            }
            if (participantesInscritosBox[6]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[6]._id,
                    sorteo_piloto_18: selectedOptionPilotSeven,
                    sorteo_constructor_18: selectedOptionConstructorSeven.constructorNombre
                });
            }
            if (participantesInscritosBox[7]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[7]._id,
                    sorteo_piloto_18: selectedOptionPilotEight,
                    sorteo_constructor_18: selectedOptionConstructorEight.constructorNombre
                });
            }
            if (participantesInscritosBox[8]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[8]._id,
                    sorteo_piloto_18: selectedOptionPilotNine,
                    sorteo_constructor_18: selectedOptionConstructorNine.constructorNombre
                });
            }
            if (participantesInscritosBox[9]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[9]._id,
                    sorteo_piloto_18: selectedOptionPilotTen,
                    sorteo_constructor_18: selectedOptionConstructorTen.constructorNombre
                });
            }
            if (participantesInscritosBox[10]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[10]._id,
                    sorteo_piloto_18: selectedOptionPilotEleven,
                    sorteo_constructor_18: selectedOptionConstructorEleven.constructorNombre
                });
            }
            if (participantesInscritosBox[11]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[11]._id,
                    sorteo_piloto_18: selectedOptionPilotTwelve,
                    sorteo_constructor_18: selectedOptionConstructorTwelve.constructorNombre
                });
            }
            if (participantesInscritosBox[12]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[12]._id,
                    sorteo_piloto_18: selectedOptionPilotThirteen,
                    sorteo_constructor_18: selectedOptionConstructorThirteen.constructorNombre
                });
            }
            if (participantesInscritosBox[13]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[13]._id,
                    sorteo_piloto_18: selectedOptionPilotForteen,
                    sorteo_constructor_18: selectedOptionConstructorForteen.constructorNombre
                });
            }
            if (participantesInscritosBox[14]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[14]._id,
                    sorteo_piloto_18: selectedOptionPilotFifteen,
                    sorteo_constructor_18: selectedOptionConstructorFifteen.constructorNombre
                });
            }
            if (participantesInscritosBox[15]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[15]._id,
                    sorteo_piloto_18: selectedOptionPilotSixteen,
                    sorteo_constructor_18: selectedOptionConstructorSixteen.constructorNombre
                });
            }
            if (participantesInscritosBox[16]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[16]._id,
                    sorteo_piloto_18: selectedOptionPilotSeventeen,
                    sorteo_constructor_18: selectedOptionConstructorSeventeen.constructorNombre
                });
            }
            if (participantesInscritosBox[17]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[17]._id,
                    sorteo_piloto_18: selectedOptionPilotEighteen,
                    sorteo_constructor_18: selectedOptionConstructorEighteen.constructorNombre
                });
            }
            if (participantesInscritosBox[18]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[18]._id,
                    sorteo_piloto_18: selectedOptionPilotNineteen,
                    sorteo_constructor_18: selectedOptionConstructorNineteen.constructorNombre
                });
            }
            if (participantesInscritosBox[19]) {
                actualizarParticipante({
                    _id: participantesInscritosBox[19]._id,
                    sorteo_piloto_18: selectedOptionPilotTwenty,
                    sorteo_constructor_18: selectedOptionConstructorTwenty.constructorNombre
                });
            }
            if (participantesInscritosWomen[0]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[0]._id,
                    sorteo_piloto_18: selectedOptionPilotOne,
                    sorteo_constructor_18: selectedOptionConstructorOne.constructorNombre
                });
            }
            if (participantesInscritosWomen[1]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[1]._id,
                    sorteo_piloto_18: selectedOptionPilotTwo,
                    sorteo_constructor_18: selectedOptionConstructorTwo.constructorNombre
                });
            }
            if (participantesInscritosWomen[2]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[2]._id,
                    sorteo_piloto_18: selectedOptionPilotThree,
                    sorteo_constructor_18: selectedOptionConstructorThree.constructorNombre
                });
            }
            if (participantesInscritosWomen[3]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[3]._id,
                    sorteo_piloto_18: selectedOptionPilotFour,
                    sorteo_constructor_18: selectedOptionConstructorFour.constructorNombre
                });
            }
            if (participantesInscritosWomen[4]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[4]._id,
                    sorteo_piloto_18: selectedOptionPilotFive,
                    sorteo_constructor_18: selectedOptionConstructorFive.constructorNombre
                });
            }
            if (participantesInscritosWomen[5]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[5]._id,
                    sorteo_piloto_18: selectedOptionPilotSix,
                    sorteo_constructor_18: selectedOptionConstructorSix.constructorNombre
                });
            }
            if (participantesInscritosWomen[6]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[6]._id,
                    sorteo_piloto_18: selectedOptionPilotSeven,
                    sorteo_constructor_18: selectedOptionConstructorSeven.constructorNombre
                });
            }
            if (participantesInscritosWomen[7]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[7]._id,
                    sorteo_piloto_18: selectedOptionPilotEight,
                    sorteo_constructor_18: selectedOptionConstructorEight.constructorNombre
                });
            }
            if (participantesInscritosWomen[8]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[8]._id,
                    sorteo_piloto_18: selectedOptionPilotNine,
                    sorteo_constructor_18: selectedOptionConstructorNine.constructorNombre
                });
            }
            if (participantesInscritosWomen[9]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[9]._id,
                    sorteo_piloto_18: selectedOptionPilotTen,
                    sorteo_constructor_18: selectedOptionConstructorTen.constructorNombre
                });
            }
            if (participantesInscritosWomen[10]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[10]._id,
                    sorteo_piloto_18: selectedOptionPilotEleven,
                    sorteo_constructor_18: selectedOptionConstructorEleven.constructorNombre
                });
            }
            if (participantesInscritosWomen[11]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[11]._id,
                    sorteo_piloto_18: selectedOptionPilotTwelve,
                    sorteo_constructor_18: selectedOptionConstructorTwelve.constructorNombre
                });
            }
            if (participantesInscritosWomen[12]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[12]._id,
                    sorteo_piloto_18: selectedOptionPilotThirteen,
                    sorteo_constructor_18: selectedOptionConstructorThirteen.constructorNombre
                });
            }
            if (participantesInscritosWomen[13]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[13]._id,
                    sorteo_piloto_18: selectedOptionPilotForteen,
                    sorteo_constructor_18: selectedOptionConstructorForteen.constructorNombre
                });
            }
            if (participantesInscritosWomen[14]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[14]._id,
                    sorteo_piloto_18: selectedOptionPilotFifteen,
                    sorteo_constructor_18: selectedOptionConstructorFifteen.constructorNombre
                });
            }
            if (participantesInscritosWomen[15]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[15]._id,
                    sorteo_piloto_18: selectedOptionPilotSixteen,
                    sorteo_constructor_18: selectedOptionConstructorSixteen.constructorNombre
                });
            }
            if (participantesInscritosWomen[16]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[16]._id,
                    sorteo_piloto_18: selectedOptionPilotSeventeen,
                    sorteo_constructor_18: selectedOptionConstructorSeventeen.constructorNombre
                });
            }
            if (participantesInscritosWomen[17]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[17]._id,
                    sorteo_piloto_18: selectedOptionPilotEighteen,
                    sorteo_constructor_18: selectedOptionConstructorEighteen.constructorNombre
                });
            }
            if (participantesInscritosWomen[18]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[18]._id,
                    sorteo_piloto_18: selectedOptionPilotNineteen,
                    sorteo_constructor_18: selectedOptionConstructorNineteen.constructorNombre
                });
            }
            if (participantesInscritosWomen[19]) {
                actualizarParticipante({
                    _id: participantesInscritosWomen[19]._id,
                    sorteo_piloto_18: selectedOptionPilotTwenty,
                    sorteo_constructor_18: selectedOptionConstructorTwenty.constructorNombre
                });
            }
        }
    }

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <MainNavigation/>
            <section className='block charging-draw'>
                <h1>{index} {currentRace.pais} - {currentRace.ciudad}</h1>
                <h2>Sorteo</h2>
                <div className='table'>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='table-header'>POS. GEN.</div>
                        </div>
                        <div className='pilot-field'>
                            <div className='table-header'>PILOTO</div>
                        </div>
                        <div className='team-field'>
                            <div className='table-header'>EQUIPO</div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P1</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotOne}>
                                    <span className='text'>{selectedOptionPilotOne || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotOne && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotOne(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorOne}>
                                    <span className='text'>{selectedOptionConstructorOne.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorOne && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorOne({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P2</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTwo}>
                                    <span className='text'>{selectedOptionPilotTwo || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTwo && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTwo(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorTwo}>
                                    <span className='text'>{selectedOptionConstructorTwo.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorTwo && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorTwo({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P3</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotThree}>
                                    <span className='text'>{selectedOptionPilotThree || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotThree && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotThree(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorThree}>
                                    <span className='text'>{selectedOptionConstructorThree.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorThree && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorThree({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P4</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotFour}>
                                    <span className='text'>{selectedOptionPilotFour || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotFour && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotFour(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorFour}>
                                    <span className='text'>{selectedOptionConstructorFour.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorFour && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorFour({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P5</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotFive}>
                                    <span className='text'>{selectedOptionPilotFive || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotFive && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotFive(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorFive}>
                                    <span className='text'>{selectedOptionConstructorFive.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorFive && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorFive({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P6</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSix}>
                                    <span className='text'>{selectedOptionPilotSix || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSix && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSix(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorSix}>
                                    <span className='text'>{selectedOptionConstructorSix.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorSix && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorSix({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P7</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSeven}>
                                    <span className='text'>{selectedOptionPilotSeven || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSeven && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSeven(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorSeven}>
                                    <span className='text'>{selectedOptionConstructorSeven.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorSeven && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorSeven({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P8</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotEight}>
                                    <span className='text'>{selectedOptionPilotEight || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotEight && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotEight(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorEight}>
                                    <span className='text'>{selectedOptionConstructorEight.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorEight && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorEight({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P9</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotNine}>
                                    <span className='text'>{selectedOptionPilotNine || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotNine && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotNine(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorNine}>
                                    <span className='text'>{selectedOptionConstructorNine.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorNine && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorNine({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P10</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTen}>
                                    <span className='text'>{selectedOptionPilotTen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorTen}>
                                    <span className='text'>{selectedOptionConstructorTen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorTen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorTen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P11</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotEleven}>
                                    <span className='text'>{selectedOptionPilotEleven || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotEleven && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotEleven(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorEleven}>
                                    <span className='text'>{selectedOptionConstructorEleven.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorEleven && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorEleven({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P12</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTwelve}>
                                    <span className='text'>{selectedOptionPilotTwelve || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTwelve && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTwelve(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorTwelve}>
                                    <span className='text'>{selectedOptionConstructorTwelve.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorTwelve && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorTwelve({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P13</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotThirteen}>
                                    <span className='text'>{selectedOptionPilotThirteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotThirteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotThirteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorThirteen}>
                                    <span className='text'>{selectedOptionConstructorThirteen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorThirteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorThirteen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P14</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotForteen}>
                                    <span className='text'>{selectedOptionPilotForteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotForteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotForteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorForteen}>
                                    <span className='text'>{selectedOptionConstructorForteen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorForteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorForteen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P15</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotFifteen}>
                                    <span className='text'>{selectedOptionPilotFifteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotFifteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotFifteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorFifteen}>
                                    <span className='text'>{selectedOptionConstructorFifteen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorFifteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorFifteen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P16</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSixteen}>
                                    <span className='text'>{selectedOptionPilotSixteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSixteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSixteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorSixteen}>
                                    <span className='text'>{selectedOptionConstructorSixteen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorSixteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorSixteen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P17</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSeventeen}>
                                    <span className='text'>{selectedOptionPilotSeventeen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSeventeen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSeventeen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorSeventeen}>
                                    <span className='text'>{selectedOptionConstructorSeventeen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorSeventeen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorSeventeen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P18</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotEighteen}>
                                    <span className='text'>{selectedOptionPilotEighteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotEighteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotEighteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorEighteen}>
                                    <span className='text'>{selectedOptionConstructorEighteen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorEighteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorEighteen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P19</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotNineteen}>
                                    <span className='text'>{selectedOptionPilotNineteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotNineteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotNineteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorNineteen}>
                                    <span className='text'>{selectedOptionConstructorNineteen.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorNineteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorNineteen({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P20</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTwenty}>
                                    <span className='text'>{selectedOptionPilotTwenty || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTwenty && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTwenty(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='team-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingConstructorTwenty}>
                                    <span className='text'>{selectedOptionConstructorTwenty.constructorNombre || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenConstructorTwenty && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {constructores.map((constructor, index) => (
                                            <li
                                                key={index}
                                                onClick={
                                                    onOptionClickedConstructorTwenty({
                                                        constructorId: constructor.id,
                                                        constructorNombre: constructor.nombre
                                                    })
                                                }
                                            >
                                                {constructor.nombre}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='save-btn'
                    onClick={saveInfo}
                >
                    Guardar
                </div>
                <Link to={"/administrador"} className="back">
                    <div className="text">Regresar</div>
                </Link>
            </section>
        </div>
    )
}

export default Sorteo;