import React, { useState, useContext, useEffect } from 'react';
import MainNavigation from '../partials/MainNavigation';
import { Link, Redirect } from 'react-router-dom';
import ListaPilotos from './ListaPilotos';
import ListaConstructores from './ListaConstructores';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContext from '../../context/autenticacion/authContext';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import listaCarrerasContext from '../../context/listaCarreras/listaCarrerasContext';
import Preguntas from './Preguntas';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import '../../design/styles/modal.scss';
import { DateTime } from "luxon"

// Import styles
import backIcon from '../../design/imgs/arrow-l-1.svg';
import arrowActive from '../../design/imgs/arrow-active.svg';
import checkIcon from '../../design/imgs/check.svg';
import '../../design/styles/strategy.scss';
import '../../design/styles/modal-selection.scss';

import firstPlace from '../../design/imgs/pop-up.jpg';
import close from '../../design/imgs/close.svg';
import closeHover from '../../design/imgs/close2.svg';

const Background = styled.div`
    background-image: url(${firstPlace});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const EstrategiaCarrera = () => {

    let comodin_pilot = localStorage.getItem('comodinPiloto');
    let comodin_constructor = localStorage.getItem('comodinConstructor');

    // Configuración del modal de material-ui
    //const[modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario, usuarioAutenticado} = authContext;

    const [pilotoSeleccionado, SeleccionarPiloto] = useState('');

    const [constructorSeleccionado, SeleccionarConstructor] = useState({});

    const [optionSelected, changeOption] = useState('');

    const [selectedOption, setSelectedOption] = useState(null);

    const [selectedOptionPilotDnf, setSelectedOptionPilotDnf] = useState(null);

    const [selectedOptionPilotDhl, setSelectedOptionPilotDhl] = useState(null);

    const [selectedOptionPilot, setSelectedOptionPilot] = useState(null);

    const [selectedOptionConstructor, setSelectedOptionConstructor] = useState({});

    const [selectedPosOne, setSelectedPosOne] = useState(null);
    const [selectedPosTwo, setSelectedPosTwo] = useState(null);
    const [selectedPosThree, setSelectedPosThree] = useState(null);
    const [selectedPosFour, setSelectedPosFour] = useState(null);
    const [selectedPosFive, setSelectedPosFive] = useState(null);

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {actualizarParticipante, participantesInscritos, obtenerParticipantes} = listaContext;

    // Obtener las tareas del proyecto
    const carrerasContext = useContext(listaCarrerasContext);
    const {actualizarCarrera} = carrerasContext;

    let concurso = JSON.parse(localStorage.getItem('currentConcurso')),
        currentId;

    participantesInscritos.map(participante => {
        if (participante.id_participante === usuario._id) {
            currentId = participante._id
        }
    })

    let currentRace = '',
        index = '',
        carrerasTerminadas = localStorage.getItem('carrerasTerminadas');
    if (localStorage.getItem('currentRace')) {
        currentRace = JSON.parse(localStorage.getItem('currentRace'));
        index = localStorage.getItem('index');
    }

    const selectOption = e => {
        e.preventDefault();
        
        let currentOpt = e.target,
            dataOpt = currentOpt.getAttribute('data-opt'),
            allOpts = document.querySelectorAll('.opt');

        allOpts.forEach(opt => {
            opt.classList.remove('active');
        });
        
        currentOpt.classList.add('active');
        changeOption(dataOpt);
    }

    let nuevoParticipante = {};

    if (index === '01') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_01: pilotoSeleccionado,
            pick_constructor_01: constructorSeleccionado.constructorNombre,
            id_pick_constructor_01: constructorSeleccionado.constructorId,
            pick_pregunta1_01: selectedOption,
            pick_pregunta2_01: selectedOptionPilotDhl,
            pick_pregunta3_01: selectedOptionPilotDnf,
            pick_posicion1_01: selectedPosOne,
            pick_posicion2_01: selectedPosTwo,
            pick_posicion3_01: selectedPosThree,
            pick_posicion4_01: selectedPosFour,
            pick_posicion5_01: selectedPosFive,
            carreras_terminadas: 1,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '02') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_02: pilotoSeleccionado,
            pick_constructor_02: constructorSeleccionado.constructorNombre,
            id_pick_constructor_02: constructorSeleccionado.constructorId,
            pick_pregunta1_02: selectedOption,
            pick_pregunta2_02: selectedOptionPilotDhl,
            pick_pregunta3_02: selectedOptionPilotDnf,
            pick_posicion1_02: selectedPosOne,
            pick_posicion2_02: selectedPosTwo,
            pick_posicion3_02: selectedPosThree,
            pick_posicion4_02: selectedPosFour,
            pick_posicion5_02: selectedPosFive,
            carreras_terminadas: 2,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '03') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_03: pilotoSeleccionado,
            pick_constructor_03: constructorSeleccionado.constructorNombre,
            id_pick_constructor_03: constructorSeleccionado.constructorId,
            pick_pregunta1_03: selectedOption,
            pick_pregunta2_03: selectedOptionPilotDhl,
            pick_pregunta3_03: selectedOptionPilotDnf,
            pick_posicion1_03: selectedPosOne,
            pick_posicion2_03: selectedPosTwo,
            pick_posicion3_03: selectedPosThree,
            pick_posicion4_03: selectedPosFour,
            pick_posicion5_03: selectedPosFive,
            carreras_terminadas: 3,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '04') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_04: pilotoSeleccionado,
            pick_constructor_04: constructorSeleccionado.constructorNombre,
            id_pick_constructor_04: constructorSeleccionado.constructorId,
            pick_pregunta1_04: selectedOption,
            pick_pregunta2_04: selectedOptionPilotDhl,
            pick_pregunta3_04: selectedOptionPilotDnf,
            pick_posicion1_04: selectedPosOne,
            pick_posicion2_04: selectedPosTwo,
            pick_posicion3_04: selectedPosThree,
            pick_posicion4_04: selectedPosFour,
            pick_posicion5_04: selectedPosFive,
            carreras_terminadas: 4,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '05') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_05: pilotoSeleccionado,
            pick_constructor_05: constructorSeleccionado.constructorNombre,
            id_pick_constructor_05: constructorSeleccionado.constructorId,
            pick_pregunta1_05: selectedOption,
            pick_pregunta2_05: selectedOptionPilotDhl,
            pick_pregunta3_05: selectedOptionPilotDnf,
            pick_posicion1_05: selectedPosOne,
            pick_posicion2_05: selectedPosTwo,
            pick_posicion3_05: selectedPosThree,
            pick_posicion4_05: selectedPosFour,
            pick_posicion5_05: selectedPosFive,
            carreras_terminadas: 5,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '06') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_06: pilotoSeleccionado,
            pick_constructor_06: constructorSeleccionado.constructorNombre,
            id_pick_constructor_06: constructorSeleccionado.constructorId,
            pick_pregunta1_06: selectedOption,
            pick_pregunta2_06: selectedOptionPilotDhl,
            pick_pregunta3_06: selectedOptionPilotDnf,
            pick_posicion1_06: selectedPosOne,
            pick_posicion2_06: selectedPosTwo,
            pick_posicion3_06: selectedPosThree,
            pick_posicion4_06: selectedPosFour,
            pick_posicion5_06: selectedPosFive,
            carreras_terminadas: 6,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '07') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_07: pilotoSeleccionado,
            pick_constructor_07: constructorSeleccionado.constructorNombre,
            id_pick_constructor_07: constructorSeleccionado.constructorId,
            pick_pregunta1_07: selectedOption,
            pick_pregunta2_07: selectedOptionPilotDhl,
            pick_pregunta3_07: selectedOptionPilotDnf,
            pick_posicion1_07: selectedPosOne,
            pick_posicion2_07: selectedPosTwo,
            pick_posicion3_07: selectedPosThree,
            pick_posicion4_07: selectedPosFour,
            pick_posicion5_07: selectedPosFive,
            carreras_terminadas: 7,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '08') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_08: pilotoSeleccionado,
            pick_constructor_08: constructorSeleccionado.constructorNombre,
            id_pick_constructor_08: constructorSeleccionado.constructorId,
            pick_pregunta1_08: selectedOption,
            pick_pregunta2_08: selectedOptionPilotDhl,
            pick_pregunta3_08: selectedOptionPilotDnf,
            pick_posicion1_08: selectedPosOne,
            pick_posicion2_08: selectedPosTwo,
            pick_posicion3_08: selectedPosThree,
            pick_posicion4_08: selectedPosFour,
            pick_posicion5_08: selectedPosFive,
            carreras_terminadas: 8,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '09') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_09: pilotoSeleccionado,
            pick_constructor_09: constructorSeleccionado.constructorNombre,
            id_pick_constructor_09: constructorSeleccionado.constructorId,
            pick_pregunta1_09: selectedOption,
            pick_pregunta2_09: selectedOptionPilotDhl,
            pick_pregunta3_09: selectedOptionPilotDnf,
            pick_posicion1_09: selectedPosOne,
            pick_posicion2_09: selectedPosTwo,
            pick_posicion3_09: selectedPosThree,
            pick_posicion4_09: selectedPosFour,
            pick_posicion5_09: selectedPosFive,
            carreras_terminadas: 9,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '10') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_10: pilotoSeleccionado,
            pick_constructor_10: constructorSeleccionado.constructorNombre,
            id_pick_constructor_10: constructorSeleccionado.constructorId,
            pick_pregunta1_10: selectedOption,
            pick_pregunta2_10: selectedOptionPilotDhl,
            pick_pregunta3_10: selectedOptionPilotDnf,
            pick_posicion1_10: selectedPosOne,
            pick_posicion2_10: selectedPosTwo,
            pick_posicion3_10: selectedPosThree,
            pick_posicion4_10: selectedPosFour,
            pick_posicion5_10: selectedPosFive,
            carreras_terminadas: 10,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '11') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_11: pilotoSeleccionado,
            pick_constructor_11: constructorSeleccionado.constructorNombre,
            id_pick_constructor_11: constructorSeleccionado.constructorId,
            pick_pregunta1_11: selectedOption,
            pick_pregunta2_11: selectedOptionPilotDhl,
            pick_pregunta3_11: selectedOptionPilotDnf,
            pick_posicion1_11: selectedPosOne,
            pick_posicion2_11: selectedPosTwo,
            pick_posicion3_11: selectedPosThree,
            pick_posicion4_11: selectedPosFour,
            pick_posicion5_11: selectedPosFive,
            carreras_terminadas: 11,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '12') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_12: pilotoSeleccionado,
            pick_constructor_12: constructorSeleccionado.constructorNombre,
            id_pick_constructor_12: constructorSeleccionado.constructorId,
            pick_pregunta1_12: selectedOption,
            pick_pregunta2_12: selectedOptionPilotDhl,
            pick_pregunta3_12: selectedOptionPilotDnf,
            pick_posicion1_12: selectedPosOne,
            pick_posicion2_12: selectedPosTwo,
            pick_posicion3_12: selectedPosThree,
            pick_posicion4_12: selectedPosFour,
            pick_posicion5_12: selectedPosFive,
            carreras_terminadas: 12,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '13') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_13: pilotoSeleccionado,
            pick_constructor_13: constructorSeleccionado.constructorNombre,
            id_pick_constructor_13: constructorSeleccionado.constructorId,
            pick_pregunta1_13: selectedOption,
            pick_pregunta2_13: selectedOptionPilotDhl,
            pick_pregunta3_13: selectedOptionPilotDnf,
            pick_posicion1_13: selectedPosOne,
            pick_posicion2_13: selectedPosTwo,
            pick_posicion3_13: selectedPosThree,
            pick_posicion4_13: selectedPosFour,
            pick_posicion5_13: selectedPosFive,
            carreras_terminadas: 13,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '14') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_14: pilotoSeleccionado,
            pick_constructor_14: constructorSeleccionado.constructorNombre,
            id_pick_constructor_14: constructorSeleccionado.constructorId,
            pick_pregunta1_14: selectedOption,
            pick_pregunta2_14: selectedOptionPilotDhl,
            pick_pregunta3_14: selectedOptionPilotDnf,
            pick_posicion1_14: selectedPosOne,
            pick_posicion2_14: selectedPosTwo,
            pick_posicion3_14: selectedPosThree,
            pick_posicion4_14: selectedPosFour,
            pick_posicion5_14: selectedPosFive,
            carreras_terminadas: 14,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '15') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_15: pilotoSeleccionado,
            pick_constructor_15: constructorSeleccionado.constructorNombre,
            id_pick_constructor_15: constructorSeleccionado.constructorId,
            pick_pregunta1_15: selectedOption,
            pick_pregunta2_15: selectedOptionPilotDhl,
            pick_pregunta3_15: selectedOptionPilotDnf,
            pick_posicion1_15: selectedPosOne,
            pick_posicion2_15: selectedPosTwo,
            pick_posicion3_15: selectedPosThree,
            pick_posicion4_15: selectedPosFour,
            pick_posicion5_15: selectedPosFive,
            carreras_terminadas: 15,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '16') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_16: pilotoSeleccionado,
            pick_constructor_16: constructorSeleccionado.constructorNombre,
            id_pick_constructor_16: constructorSeleccionado.constructorId,
            pick_pregunta1_16: selectedOption,
            pick_pregunta2_16: selectedOptionPilotDhl,
            pick_pregunta3_16: selectedOptionPilotDnf,
            pick_posicion1_16: selectedPosOne,
            pick_posicion2_16: selectedPosTwo,
            pick_posicion3_16: selectedPosThree,
            pick_posicion4_16: selectedPosFour,
            pick_posicion5_16: selectedPosFive,
            carreras_terminadas: 16,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '17') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_17: pilotoSeleccionado,
            pick_constructor_17: constructorSeleccionado.constructorNombre,
            id_pick_constructor_17: constructorSeleccionado.constructorId,
            pick_pregunta1_17: selectedOption,
            pick_pregunta2_17: selectedOptionPilotDhl,
            pick_pregunta3_17: selectedOptionPilotDnf,
            pick_posicion1_17: selectedPosOne,
            pick_posicion2_17: selectedPosTwo,
            pick_posicion3_17: selectedPosThree,
            pick_posicion4_17: selectedPosFour,
            pick_posicion5_17: selectedPosFive,
            carreras_terminadas: 17,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '18') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_18: pilotoSeleccionado,
            pick_constructor_18: constructorSeleccionado.constructorNombre,
            id_pick_constructor_18: constructorSeleccionado.constructorId,
            pick_pregunta1_18: selectedOption,
            pick_pregunta2_18: selectedOptionPilotDhl,
            pick_pregunta3_18: selectedOptionPilotDnf,
            pick_posicion1_18: selectedPosOne,
            pick_posicion2_18: selectedPosTwo,
            pick_posicion3_18: selectedPosThree,
            pick_posicion4_18: selectedPosFour,
            pick_posicion5_18: selectedPosFive,
            carreras_terminadas: 18,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '19') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_19: pilotoSeleccionado,
            pick_constructor_19: constructorSeleccionado.constructorNombre,
            id_pick_constructor_19: constructorSeleccionado.constructorId,
            pick_pregunta1_19: selectedOption,
            pick_pregunta2_19: selectedOptionPilotDhl,
            pick_pregunta3_19: selectedOptionPilotDnf,
            pick_posicion1_19: selectedPosOne,
            pick_posicion2_19: selectedPosTwo,
            pick_posicion3_19: selectedPosThree,
            pick_posicion4_19: selectedPosFour,
            pick_posicion5_19: selectedPosFive,
            carreras_terminadas: 19,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '20') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_20: pilotoSeleccionado,
            pick_constructor_20: constructorSeleccionado.constructorNombre,
            id_pick_constructor_20: constructorSeleccionado.constructorId,
            pick_pregunta1_20: selectedOption,
            pick_pregunta2_20: selectedOptionPilotDhl,
            pick_pregunta3_20: selectedOptionPilotDnf,
            pick_posicion1_20: selectedPosOne,
            pick_posicion2_20: selectedPosTwo,
            pick_posicion3_20: selectedPosThree,
            pick_posicion4_20: selectedPosFour,
            pick_posicion5_20: selectedPosFive,
            carreras_terminadas: 20,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '21') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_21: pilotoSeleccionado,
            pick_constructor_21: constructorSeleccionado.constructorNombre,
            id_pick_constructor_21: constructorSeleccionado.constructorId,
            pick_pregunta1_21: selectedOption,
            pick_pregunta2_21: selectedOptionPilotDhl,
            pick_pregunta3_21: selectedOptionPilotDnf,
            pick_posicion1_21: selectedPosOne,
            pick_posicion2_21: selectedPosTwo,
            pick_posicion3_21: selectedPosThree,
            pick_posicion4_21: selectedPosFour,
            pick_posicion5_21: selectedPosFive,
            carreras_terminadas: 21,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '22') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_22: pilotoSeleccionado,
            pick_constructor_22: constructorSeleccionado.constructorNombre,
            id_pick_constructor_22: constructorSeleccionado.constructorId,
            pick_pregunta1_22: selectedOption,
            pick_pregunta2_22: selectedOptionPilotDhl,
            pick_pregunta3_22: selectedOptionPilotDnf,
            pick_posicion1_22: selectedPosOne,
            pick_posicion2_22: selectedPosTwo,
            pick_posicion3_22: selectedPosThree,
            pick_posicion4_22: selectedPosFour,
            pick_posicion5_22: selectedPosFive,
            carreras_terminadas: 22,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    if (index === '23') {
        nuevoParticipante = {
            _id: currentId,
            pick_piloto_23: pilotoSeleccionado,
            pick_constructor_23: constructorSeleccionado.constructorNombre,
            id_pick_constructor_23: constructorSeleccionado.constructorId,
            pick_pregunta1_23: selectedOption,
            pick_pregunta2_23: selectedOptionPilotDhl,
            pick_pregunta3_23: selectedOptionPilotDnf,
            pick_posicion1_23: selectedPosOne,
            pick_posicion2_23: selectedPosTwo,
            pick_posicion3_23: selectedPosThree,
            pick_posicion4_23: selectedPosFour,
            pick_posicion5_23: selectedPosFive,
            carreras_terminadas: 23,
            comodines_disponibles_pilotos: comodin_pilot - 1,
            comodines_disponibles_constructores: comodin_constructor - 1
        }
    }

    const nuevaCarrera = {
        _id: currentRace._id,
        concluida: true
    }

    const openModal = () => {
        usuarioAutenticado();
        if (usuario) {
            console.log(usuario);
            if (concurso.nombre === 'GOAT') {
                if (pilotoSeleccionado && constructorSeleccionado.constructorNombre && selectedPosOne && selectedPosTwo && selectedPosThree && selectedPosFour && selectedPosFive) {
                    if(
                        selectedPosOne === selectedPosTwo ||
                        selectedPosOne === selectedPosThree ||
                        selectedPosOne === selectedPosFour ||
                        selectedPosOne === selectedPosFive ||
                        selectedPosTwo === selectedPosThree ||
                        selectedPosTwo === selectedPosFour ||
                        selectedPosTwo === selectedPosFive ||
                        selectedPosThree === selectedPosFour ||
                        selectedPosThree === selectedPosFive ||
                        selectedPosFour === selectedPosFive
                    ) {
                        mostrarAlerta('No pueden haber respuestas duplicadas', 'alerta-error');
                    } else {
                        handleOpen();
                    }
                } else {
                    mostrarAlerta('Se deben seleccionar todas las opciones', 'alerta-error');
                }
            } else {
                if (pilotoSeleccionado && constructorSeleccionado.constructorNombre && selectedOption && selectedOptionPilotDhl && selectedOptionPilotDnf) {
                    handleOpen();
                } else {
                    mostrarAlerta('Se deben seleccionar todas las opciones', 'alerta-error');
                }
            }
        } else {
            <Redirect to="/"/>
        }
    }

    const onSubmit = () => {

        usuarioAutenticado();
        if (usuario) {
    
            // crea un nuevo objeto `Date`
            var nowDay = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("dd"),
                nowMonth = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("LL"),
                nowYear = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("y"),
                nowDate = Date.parse(nowYear + '-' + nowMonth + '-' + nowDay),
                nowTimeHours = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("HH"),
                nowTimeMinutes = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("mm"),
                nowTimeHoursFormated = Date.parse(nowYear + '-' + nowMonth + '-' + nowDay+'T'+nowTimeHours+':'+nowTimeMinutes),
                deadline_fecha_formated = Date.parse(currentRace.deadline_fecha),
                deadline_hora_formated = Date.parse(currentRace.deadline_fecha+'T'+currentRace.deadline_hora);

            if (localStorage.getItem("currentRace")) {
                currentRace = JSON.parse(localStorage.getItem("currentRace"));

                if (nowDate <= deadline_fecha_formated) {

                    if(nowDate < deadline_fecha_formated) {
                        console.log('Ejecuta la carga de picks');
                        actualizarParticipante(nuevoParticipante);
                        actualizarCarrera(nuevaCarrera);
                        localStorage.setItem('carrerasTerminadas', index);
                    } else {
                        if(nowTimeHoursFormated < deadline_hora_formated) {
                            console.log('Ejecuta la carga de picks');
                            actualizarParticipante(nuevoParticipante);
                            actualizarCarrera(nuevaCarrera);
                            localStorage.setItem('carrerasTerminadas', index);
                        } else {
                            console.log('Ya pasó la hora');
                            actualizarParticipante(
                                {
                                    _id: currentId,
                                    carreras_terminadas: index
                                }
                            )
                            localStorage.setItem('carrerasTerminadas', index)
                        }
                    }
                } else {    
                    console.log('Ya pasó la fecha');
                    actualizarParticipante(
                            {
                                _id: currentId,
                                carreras_terminadas: index
                            }
                    )
                    localStorage.setItem('carrerasTerminadas', index)
                }
            }
        } else {
            mostrarAlerta('Deadline terminado', 'alerta-error');
        }
    }

    useEffect(() => {
        obtenerParticipantes(concurso._id);
    }, [])

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <MainNavigation/>
            <section className='block strategy'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>F1 QUINIELA 2024</div>
                            <div className='desc'>{concurso.nombre}</div>
                            {participantesInscritos.map(participante => (
                                (participante.id_participante === usuario._id)
                                    ?
                                        (index === '18' && participante.sorteo_piloto_18 && participante.sorteo_constructor_18)
                                            ?
                                                <div key={participante._id}>
                                                    <div>resultados del Sorteo:</div>
                                                    <div>Piloto - <strong>{participante.sorteo_piloto_18}</strong></div>
                                                    <div>Constructor - <strong>{participante.sorteo_constructor_18}</strong></div>
                                                </div>
                                            :
                                                null
                                    :
                                        null
                            ))}
                        </div>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='select-strategy'>
                                    <div className='strategy-header'>{index} - {currentRace.pais} · {currentRace.ciudad}</div>
                                    <div className='opts-wrapper'>
                                        <div
                                            className={(pilotoSeleccionado) ? 'opt opt-selected' : 'opt'}
                                            data-opt='piloto'
                                            onClick={selectOption}
                                        >
                                            <div className='arrow-active'><img src={arrowActive} alt='arrow'/></div>
                                            <div className='opt-checked'><img src={checkIcon} alt='checked'/></div>
                                            <div className='opt-title'>Pilotos</div>
                                            <div className='opt-desc hidden-xs'>Elige a tu piloto favorio para esta carrera.</div>
                                        </div>
                                        <div
                                            className={(constructorSeleccionado.constructorNombre) ? 'opt opt-selected' : 'opt'}
                                            data-opt='equipo'
                                            onClick={selectOption}
                                        >
                                            <div className='arrow-active'><img src={arrowActive} alt='arrow'/></div>
                                            <div className='opt-checked'><img src={checkIcon} alt='checked'/></div>
                                            <div className='opt-title'>Equipos</div>
                                            <div className='opt-desc hidden-xs'>Elige a tu equipo constructor favorito para esta carrera.</div>
                                        </div>
                                        <div
                                            className={
                                                (concurso.nombre === 'GOAT')
                                                    ?
                                                        (selectedPosOne && selectedPosTwo && selectedPosThree && selectedPosFour && selectedPosFive)
                                                            ?
                                                                'opt opt-selected'
                                                            :
                                                                'opt'
                                                    :
                                                        (selectedOptionPilotDhl && selectedOptionPilotDnf && selectedOption)
                                                            ?
                                                                'opt opt-selected'
                                                            :
                                                                'opt'
                                            }
                                            data-opt='preguntas'
                                            onClick={selectOption}
                                        >
                                            <div className='arrow-active'><img src={arrowActive} alt='arrow'/></div>
                                            <div className='opt-checked'><img src={checkIcon} alt='checked'/></div>
                                            <div className='opt-title'>Preguntas</div>
                                            <div className='opt-desc hidden-xs'>Selecciona tu opción favorita para cada pregunta.</div>
                                        </div>
                                    </div>
                                    <div className='opt-desc visbible-xs'>
                                        {optionSelected === 'piloto' ? 'Elige a tu piloto favorio para esta carrera.' : null}
                                        {optionSelected === 'equipo' ? 'Elige a tu equipo constructor favorito para esta carrera.' : null}
                                        {optionSelected === 'preguntas' ? 'Selecciona tu opción favorita para cada pregunta.' : null}
                                    </div>
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                (pilotoSeleccionado && constructorSeleccionado.constructorNombre && selectedPosOne && selectedPosTwo && selectedPosThree && selectedPosFour && selectedPosFive)
                                                    ?
                                                        <Link
                                                            onClick={openModal}
                                                            to='#'
                                                            className='submit-btn'
                                                        >Continuar</Link>
                                                    :
                                                        null
                                            :
                                                (pilotoSeleccionado && constructorSeleccionado.constructorNombre && selectedOptionPilotDhl && selectedOptionPilotDnf && selectedOption)
                                                    ?
                                                        <Link
                                                            onClick={openModal}
                                                            to='#'
                                                            className='submit-btn'
                                                        >Continuar</Link>
                                                    :
                                                        null
                                    }
                                </div>
                                <div className='lists-wrapper'>
                                    {optionSelected === '' ? <h1>Selecciona una opción</h1> : null}
                                    {
                                        optionSelected === 'piloto'
                                            ?
                                                <ListaPilotos
                                                    participantesInscritos={participantesInscritos}
                                                    pilotoSeleccionado={pilotoSeleccionado}
                                                    SeleccionarPiloto={SeleccionarPiloto}
                                                    selectedOptionPilot={selectedOptionPilot}
                                                    setSelectedOptionPilot={setSelectedOptionPilot}
                                                />
                                            :
                                                null
                                    }
                                    {
                                        optionSelected === 'equipo'
                                            ?
                                                <ListaConstructores
                                                    participantesInscritos={participantesInscritos}
                                                    constructorSeleccionado={constructorSeleccionado}
                                                    SeleccionarConstructor={SeleccionarConstructor}
                                                    selectedOptionConstructor={selectedOptionConstructor}
                                                    setSelectedOptionConstructor={setSelectedOptionConstructor}
                                                />
                                            :
                                                null
                                    }
                                    {
                                        optionSelected === 'preguntas'
                                            ?
                                                <Preguntas
                                                    selectedOption={selectedOption}
                                                    setSelectedOption={setSelectedOption}
                                                    selectedOptionPilotDnf={selectedOptionPilotDnf}
                                                    setSelectedOptionPilotDnf={setSelectedOptionPilotDnf}
                                                    selectedOptionPilotDhl={selectedOptionPilotDhl}
                                                    setSelectedOptionPilotDhl={setSelectedOptionPilotDhl}
                                                    selectedPosOne={selectedPosOne}
                                                    setSelectedPosOne={setSelectedPosOne}
                                                    selectedPosTwo={selectedPosTwo}
                                                    setSelectedPosTwo={setSelectedPosTwo}
                                                    selectedPosThree={selectedPosThree}
                                                    setSelectedPosThree={setSelectedPosThree}
                                                    selectedPosFour={selectedPosFour}
                                                    setSelectedPosFour={setSelectedPosFour}
                                                    selectedPosFive={selectedPosFive}
                                                    setSelectedPosFive={setSelectedPosFive}
                                                />
                                            :
                                                null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={'/carreras'} className='back'>
                    <div className='icon'>
                        <img
                            src={backIcon}
                            alt="Regresar"
                        />
                    </div>
                    <div className='text'>Regresar</div>
                </Link>
                <Modal
                    open={open}
                    onClose={() => {
                        handleClose()
                    }}
                >
                    <div className='pl-modal modal-selection'>
                        <div
                            className='close-modal'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <img className='icon-normal' src={close} alt='Botón Cerrar Modal'/>
                            <img className='icon-hover' src={closeHover} alt='Botón Cerrar Modal'/>
                        </div>
                        <Background/>
                        <div className='holder'>
                            <div className='container-fluid'>
                                <div className='header'>
                                    <div className='title'>{index} - {currentRace.pais} · {currentRace.ciudad}</div>
                                    <div className='desc'>Esta es tu selección de estrategia</div>
                                </div>
                                <div className='content'>
                                    <div className='flex-content'>
                                        <div>Piloto:</div>
                                        <div>{pilotoSeleccionado}</div>
                                    </div>
                                    <div className='flex-content'>
                                        <div>Equipo:</div>
                                        <div>{constructorSeleccionado.constructorNombre}</div>
                                    </div>
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                <>
                                                    <div className='flex-content'>
                                                        <div>Posición 1:</div>
                                                        <div>{selectedPosOne}</div>
                                                    </div>
                                                    <div className='flex-content'>
                                                        <div>Posición 2:</div>
                                                        <div>{selectedPosTwo}</div>
                                                    </div>
                                                    <div className='flex-content'>
                                                        <div>Posición 3:</div>
                                                        <div>{selectedPosThree}</div>
                                                    </div>
                                                    <div className='flex-content'>
                                                        <div>Posición 4:</div>
                                                        <div>{selectedPosFour}</div>
                                                    </div>
                                                    <div className='flex-content'>
                                                        <div>Posición 5:</div>
                                                        <div>{selectedPosFive}</div>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div className='flex-content'>
                                                        <div>Pregunta 1:</div>
                                                        <div>{selectedOption}</div>
                                                    </div>
                                                    <div className='flex-content'>
                                                        <div>Pregunta 2:</div>
                                                        <div>{selectedOptionPilotDhl}</div>
                                                    </div>
                                                    <div className='flex-content'>
                                                        <div>Pregunta 3:</div>
                                                        <div>{selectedOptionPilotDnf}</div>
                                                    </div>
                                                </>
                                    }
                                    <Link
                                        to='/resultados-carrera'
                                        className='btn-continue'
                                        onClick={onSubmit}
                                    >Guardar</Link>
                                    <div
                                        className='btn-cancel'
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >Cancelar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        </div>
    );
}

export default EstrategiaCarrera;